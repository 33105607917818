@media (max-width: 1000px) {
    h1.cvt {
        display: none;
    }

    img#bluelogo {
        width: 10em;
    }

    .container {
        top: 15% !important;
        font-size: 0.75em;
    }

    .red_b a {
        font-size: 1em;
    }
}

@media (max-width: 370px) {
    img#bluelogo {
        width: 6em;
        margin-top: 2.5em;
    }

    main.tableau{
        top: 6em;

    }
}

@media (min-width: 1001px) {
    h1#hid {
        display: none;
    }
}


@media (max-height: 810px) {
    .container {
        font-size: 0.62em;
    }

    .red_b a {
        font-size: 1em;
    }
}


.anim {
    position: absolute;
    z-index: -2;
}

.table {
    display: none;
    position: absolute;
    z-index: 1;

}

.container {
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 15%;

}

.cadre span {
    color: #d13434;
    font-weight: bold;
}

/*encadré blanc*/
.cadre {
    background-color: rgb(255, 255, 255);
    border-radius: 1.5em;
    width: 70%;
    margin: auto;
    font-family: 'Lato', sans-serif;
    padding: 1em;

}

.scrib {
    margin: 1em;
}

.haut {
    /*display : flex;*/
}

/*.gauche{*/
/*flex: 50%;*/
/*}*/

/*.droit {*/
/*flex: 1%;*/
/*!*text-align: right;*!*/
/*}*/

.milieu {
    display: flex;
}

.contenu {
    flex: 80%;
    margin: auto;
    text-align: justify;
    margin-left: 3em;
}

.white-space{
    white-space: pre-wrap;
}

/*div : salaire et nb de poste*/
.side {
    flex: 20%;
    margin-left: 2em;
}

.side div {
    margin: 2em 0 2em 0;
}

/*div : niveau et exp*/
.bas {
    display: flex;
    margin: 1em;
    text-align: center;
}


.niveau, .xp {
    flex: 50%
}

.dateoffre, .poste, .localisation {
    flex: 33%;
}


/*div : boutons*/
.red_b {
    width: 100%;
}

/*deux boutons rouge*/
.red_b a {
    width: 20%;
    margin: 2em;
    text-align: center;
    padding: .75em;
    text-transform: uppercase;;
}


/*Bouton postuler*/
.post {
    float: right;
}

footer {
    position: absolute;
    bottom: 0;
    width: 100%;

}

.share {
    float: right;
    display: flex;
    width: 20em;
    margin-bottom: 2em;
}

.ptg {
    color: white;
    flex: 50%;
    margin: auto;
}

.a2a_kit {
    margin: 1em;
    flex: 50%;
}

@media (max-height: 630px) {
    .share {
        float: right;
        display: flex;
        width: 4em;
        margin: 0;
    }

    .ptg {
        display: none;
    }
}