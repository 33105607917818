@import url('https://fonts.googleapis.com/css?family=Martel+Sans:900');


.header {
    display: inline;
    position: absolute;
    z-index: 1;
    width: 100%;
    margin: 0.25em;
}

.offres {
    width: 90%;
    margin: auto;
}


main.tableau {
    /*display: block;*/
    z-index: 1;
    top: 20em;
    width: 90%;
    overflow-y: scroll;
    position: absolute;
    touch-action: pan-y;

}

/*@media (min-width: 710px) {*/
/*h1.cvt{*/
/*display:none;*/
/*}*/
/*}*/
h1.cvt {
    /*font-family: 'Martel Sans', sans-serif;*/
    font-style: italic;
    font-size: 2.5em;
    color: #767479;
    text-transform: uppercase;
    text-align: right;
    margin: 1em;
    text-shadow: 0.1em 0.1em 0.1em #101e38;

}

table {
    /*display: block;*/
    background-color: rgb(255, 255, 255);
}

tr, td, tbody, tfoot {
    display: block;
}

thead {
    display: none;
}

tr {
    padding-bottom: 10px;
}

td {
    padding: 10px 10px 0;
    text-align: center;
}

td:before {
    content: attr(data-title);
    color: white;
    text-transform: uppercase;
    font-size: 1.4rem;
    padding-right: 10px;
    display: block;
}

table {
    width: 100%;

}

th {
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
}

thead th {
    background-color: #101e38;
    color: #fff;
    border: 1px solid #101e38;
}

tfoot th {
    display: block;
    padding: 10px;
    text-align: center;
    color: white;
}

.button {
    line-height: 0.8;
    display: inline-block;
    font-size: 1em;
    text-decoration: none;
    border-radius: 5px;
    color: #fff;
    padding: 15px 30px;
    background-color: #d13434;
}

.select {
    padding-bottom: 20px;
    border-bottom: 1px solid #28333f;
}

.select:before {
    display: none;
}

td.select {
    text-align: center;
}


@media (max-width: 720px) {
    main.tableau {
        top: 8em;
    }

    .tableau td::before {
        display: none;
    }

    .tableau tr.no-pad {
        padding-bottom: 0;
    }

}

@media (min-width: 460px) {
    td {
        text-align: left;
    }

    td:before {
        display: inline-block;
        text-align: right;
        width: 140px;
    }

    .select {
        padding-left: 160px;
    }
}

@media (min-width: 720px) {
    table {
        display: table;
    }

    tr {
        display: table-row;
    }

    td, th {
        display: table-cell;
    }

    tbody {
        display: table-row-group;
    }

    thead {
        display: table-header-group;
    }

    tfoot {
        display: table-footer-group;
    }

    td {
        border: 1px solid #28333f;
    }

    td:before {
        display: none;
    }

    td, th {
        padding: 10px;
        color: #101e38;
    }

    tr:nth-child(2n+2) td {
        background-color: #101e38;
        color: white;
    }

    tfoot th {
        display: table-cell;
    }

    .select {
        padding: 10px;
    }
}


a.btl {
    margin-top: 3em;



}