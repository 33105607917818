
#contact{
    width: 60%;
    margin:auto;

}

#contact input{
    margin-top: 4px;
    margin-bottom: 20px;
}



input{
    margin: auto;
}

#contact input[type=text],
#contact input[type=email]{

    border: 0.08em solid #9c9c9c;
    width : 100%;
    height: 2em;

}
#contact input[type=file]{
    float:right;
    margin-left:5em;
}

.submit{
    width:100%;

}

@media (min-width: 500px) {
    .red_b button{
        width:20%;
    }

}
@media (max-width: 412px) {
    .red_b button{
        width: 30%;
        height: auto;
    }

}


.red_b button {
    margin: 2em;
    text-align: center;
    padding: .75em;
    text-transform: uppercase;
    border-width: 0px;
    cursor:pointer;
    font-size: 1em;
}

form#idrec{
        display:contents;
}


.scrib form {
    text-align: justify-all;
    max-width: 100%;
}

.rgpd_check{
    text-align: center;
    font-size: 1em;
}


@media (max-width: 394px) {

    #contact input[type=file] {
        font-size: 0.4em;
        margin: 1em;
    }
}



/*Bouton d\'upload*/

.panel {
    margin: 1em;
    text-align: center;
}
.button_outer {
    background: #d13434;
    border-radius: 30px;
    text-align: center;
    height: 50px;
    width: 200px;
    display: inline-block;
    transition: 0.2s;
    position: relative;
    overflow: hidden;
    max-width: 100%;
}
.btn_upload {
    padding: 17px 30px 12px;
    color: #fff;
    text-align: center;
    position: relative;
    display: inline-block;
    overflow: hidden;
    z-index: 3;
    white-space: nowrap;
}
.btn_upload input {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    width: 100%;
    height: 105%;
    cursor: pointer;
    opacity: 0;
}
.file_uploading {
    width: 100%;
    height: 10px;
    margin-top: 20px;
    background: #ccc;
}
.file_uploading .btn_upload {
    display: none;
}
.processing_bar {
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 100%;
    border-radius: 30px;
    background: #d13434;
    transition: 3s;
}
.file_uploading .processing_bar {
    width: 100%;
}
.success_box {
    display: none;
    width: 50px;
    height: 50px;
    position: relative;
}
.success_box:before {
    content: "";
    display: block;
    width: 9px;
    height: 18px;
    border-bottom: 6px solid #fff;
    border-right: 6px solid #fff;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    left: 17px;
    top: 10px;
}
.file_uploaded .success_box {
    display: inline-block;
}
.file_uploaded {
    margin-top: 0;
    width: 50px;
    background: #d13434;
    height: 50px;
}
.uploaded_file_view {
    max-width: 300px;
    margin: 40px auto;
    text-align: center;
    position: relative;
    transition: 0.2s;
    opacity: 0;
    border: 2px solid #ddd;
    padding: 15px;
}
.file_remove {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: block;
    position: absolute;
    background: #aaa;
    line-height: 30px;
    color: #fff;
    font-size: 12px;
    cursor: pointer;
    right: -15px;
    top: -15px;
}
.file_remove:hover {
    background: #222;
    transition: 0.2s;
}
.uploaded_file_view img {
    max-width: 100%;
}
.uploaded_file_view.show {
    opacity: 1;
}
.error_msg {
    text-align: center;
    color: #f00;
}


/*permet au block contenant le cv à supprimer de disparaître quand on en a pas besoin */


#uploaded_view{
    display: none;
}

.visible{
    display: block !important;
}
