/*@use postcss-cssnext;*/


/*.align {*/
/*align-items: center;*/
/*display: flex;*/
/*flex-direction: column;*/
/*justify-content: center;*/
/*}*/


img#bluelogo {
    width: 20em;
    margin: 1.5em;
}


/*.grid {*/
/*margin-left: auto;*/
/*margin-right: auto;*/
/*max-width: var(--gridMaxWidth);*/
/*width: var(--gridWidth);*/
/*}*/


.hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}


/*:root {*/
/*--iconFill: var(--baseColor);*/
/*}*/

.icons {
    display: none;
}

.icon {
    display: inline-block;
    fill: var(--iconFill);
    font-size: 1rem;
    height: 1em;
    vertical-align: middle;
    width: 1em;
}


/*body {*/
/*!*background-color: var(--bodyBackgroundColor);*!*/
/*color: var(--bodyColor);*/
/*!*font-family: var(--bodyFontFamily), var(--bodyFontFamilyFallback);*!*/
/*font-size: var(--bodyFontSize);*/
/*font-weight: var(--bodyFontWeight);*/
/*height: 100%;*/
/*line-height: var(--bodyLineHeight);*/
/*margin: 0;*/
/*min-height: 100vh;*/
/*}*/


/*:root {*/
/*--anchorColor: #eee;*/
/*}*/

a {
    color: var(--anchorColor);
    outline: 0;
}

a:focus,
a:hover {
    cursor: pointer;
}


:root {
    --formFieldMargin: 0.875rem;
}


div.form {
    z-index: 1;
    position: fixed;
    top: 20em;
    width: 100%;
    margin: auto;
}


form.form.login {
    width: 45em;
    margin: auto;
}


/*div{*/
/*z-index: -3;*/
/*}*/
input {
    background-image: none;
    border: 0;
    color: inherit !important;
    font: inherit;
    margin: 0;
    outline: 0;
    padding: 0;
    transition: background-color 0.3s;
}

input[type="submit"] {
    cursor: pointer;
}

.form {
    margin: calc(var(--formFieldMargin) * -1);
}

.form input[type="password"],
.form input[type="text"],
.form input[type="submit"] {
    width: 100%;
}

.form__field {
    display: flex;
    margin: var(--formFieldMargin);
}

.form__input {
    flex: 1;
}


:root {
    --loginBorderRadus: 0.25rem;
    --loginColor: #eee;

    --loginInputBackgroundColor: #3b4148;
    --loginInputHoverBackgroundColor: #434a52;

    --loginLabelBackgroundColor: #363b41;

    --loginSubmitBackgroundColor: #d13434;
    --loginSubmitColor: #eee;
    --loginSubmitHoverBackgroundColor: #9c2c2c;
}

.login {
    color: var(--loginColor);
}

.login label,
.login input[type="text"],
.login input[type="password"],
.login input[type="submit"] {
    border-radius: var(--loginBorderRadus);
    padding: 1rem;
}

.login label {
    background-color: var(--loginLabelBackgroundColor);
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}

.login input[type="password"],
.login input#login__username {
    background-color: var(--loginInputBackgroundColor) !important;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.login input[type="password"]:focus,
.login input[type="password"]:hover,
    /*.login input[type="text"]:focus,*/
.login input#login__username:hover {
    background-color: var(--loginInputHoverBackgroundColor);
}

.login input[type="submit"] {
    background-color: var(--loginSubmitBackgroundColor);
    color: var(--loginSubmitColor);
    font-weight: 700;
    text-transform: uppercase;
}

.login input[type="submit"]:focus,
.login input[type="submit"]:hover {
    background-color: var(--loginSubmitHoverBackgroundColor);
}


:root {
    --paragraphMarginBottom: 1.5rem;
    --paragraphMarginTop: 1.5rem;
}

p {
    margin-bottom: var(--paragraphMarginBottom);
    margin-top: var(--paragraphMarginTop);
}

.text--center {
    text-align: center;
}


/*Annule le css défini pour chrome pour les inputs lors de l\'auto-completion*/


/*input:-webkit-autofill,*/
/*input:-webkit-autofill:hover,*/
/*input:-webkit-autofill:focus*/
/*textarea:-webkit-autofill,*/
/*textarea:-webkit-autofill:hover*/
/*textarea:-webkit-autofill:focus,*/
/*select:-webkit-autofill,*/
/*select:-webkit-autofill:hover,*/
/*select:-webkit-autofill:focus {*/
    /*-webkit-box-shadow: 0 0 0px 1000px var(--loginInputBackgroundColor) inset;*/
    /*transition: background-color 5000s ease-in-out 0s;*/
    /*-webkit-text-fill-color: #eee;*/
    /*border-radius: var(--loginBorderRadus);*/
    /*padding: 1rem;*/
    /*background-color: var(--loginInputBackgroundColor) !important;*/
/*}*/

@media (max-width: 720px) {

    #connexion {
        top: 16em;
    }

    form.form.login {
        width: 19em;
    }

}
